<template>
  <v-row justify="start">
    <v-col cols="12" lg="1">
      <v-chip class="ma-2" color="primary" label text-color="white">
        <v-icon left small class="ml-0">fa-book</v-icon>
        {{items.type}}
      </v-chip>
    </v-col>
    <v-col cols="12" lg="11">
      <v-row>
        <v-col
          v-for="(item, index) in items.data"
          :key="index"
          cols="6"
          sm="4"
          md="3"
          lg="2"
          class="mb-3"
          style="border-bottom: 10px inset #D7CCC8;">
          <!-- card 似乎自备懒加载 -->
          <v-card flat color="transparent">
            <v-img height="230" position="top left" contain :src="'/imgs/book/'+item.id+'.jpg'"></v-img>
            <v-card-title class="pl-0 pt-2 body-1">{{ item.name }}</v-card-title>
            <v-card-subtitle class="pl-0">{{ item.year }}</v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'BookCard',
  props: {
    items: {
      type: Object
    }
  },
  data: () => ({

  })
}
</script>
