<template>
  <v-container fluid>
    <!-- 书籍添加详情页，记录相关笔记 -->
    <v-row v-for="(item, index) in items" :key="index">
      <v-col :key="index">
        <BookCard :items="item"></BookCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BookCard from '@/components/card/book'
export default {
  name: 'Book',
  components: {
    BookCard
  },
  data: () => ({
    items: [{
      type: 'Web',
      data: [
        { name: 'JavaScript高级程序设计（第3版）', id: '10546125' },
        { name: 'JavaScript DOM编程艺术 （第2版）', id: '6038371' },
        { name: '深入浅出Vue.js', id: '32581281' },
        { name: 'CSS揭秘', id: '26745943' },
        { name: '写给大家看的设计书（第4版）', id: '26664522' }
      ]
    }, {
      type: '计算机',
      data: [
        { name: '大话数据结构', id: '6424904' },
        { name: '编码', id: '4822685' },
        { name: '计算机网络（第6版）', id: '26176870' }
      ]
    }, {
      type: '其他',
      data: [
        { name: '中国哲学简史', id: '20501147' }
      ]
    }, {
      type: '漫画',
      data: [
        { name: '绝顶', id: '33425595' },
        { name: '方向', id: '26975609' },
        { name: '这里', id: '27108544' }
      ]
    }]
  })
}
</script>

<style lang="scss">

</style>
